import { useMutation, useQuery } from "@apollo/client";
import {
  AnswerSurveyMutation,
  ClearSurveyMutation,
  SurveyQuery
} from "../graphql/survey";
import { useLocale, useToastMessage } from ".";

/**
 * Hook for querying and updating survey
 * @param {string} surveyId
 * @todo Test all these queries/mutations once BE is completed
 */
const useSurvey = (variables, options) => {
  const { pushToast } = useToastMessage();
  const { t } = useLocale();

  const { data, loading, error } = useQuery(SurveyQuery, {
    variables,
    onError: error => pushToast(error.message || t("unknown_error"), "critical"),
    ...options,
  });

  /** @todo handle specific answer loading variable */
  const [updateAnswer, { loading: updateLoading }] = useMutation(AnswerSurveyMutation);

  const handleUpdateSurvey = (value, questionId) =>
    updateAnswer({
      variables: {
        ...variables,
        questionId,
        answer: questionId === "39" ? { response: value } : { value },
      }
    });

  const [clearSurvey, { loading: clearSurveyLoading }] = useMutation(ClearSurveyMutation);

  const handleClearSurvey = () => clearSurvey({ variables });

  return {
    data,
    error,
    loading,
    updateLoading,
    handleUpdateSurvey,
    clearSurveyLoading,
    handleClearSurvey,
  };
};

export { useSurvey };
