
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React from "react";
import App from "next/app";
import Head from "next/head";
import { ApolloProvider, gql } from "@apollo/client";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css"; // Import the CSS
import * as Sentry from "@sentry/nextjs";
import mixpanel from "mixpanel-browser";
import { Provider as ReduxProvider } from "react-redux";
import { TimezoneModal } from "@/cloverleaf-ui/components";
import ConsentBanner from "../components/composite/ConsentBanner";
import { EmployeeToolsProvider } from "../components/composite/EmployeeToolbox";
import "../components/hoc/tooltip.css"; // css override for cloverleaf styles
import "../components/utils/icons"; // Bootstrap the icons we use.
import "../components/utils/normalize.css";
import "../components/utils/style.css";
import { SentryUserManager } from "../components/v2/SentryUserManager";
import { ToastMessages } from "../components/v2/ToastMessages";
import { initializeApollo, useApollo } from "../lib/apolloClient";
import { useStore } from "../lib/redux";
import segment from "../lib/segment";
import { checkAuthStatusFromContext, useAuth } from "@/lib/hooks";
import "@/modules/global-font.css";
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
const meta = {
    twitterHandle: "cloverleafme",
    twitterImage: "/images/icons-512.png",
    previewImage: "/images/icons-512.png",
    siteName: "Cloverleaf | Build Better Teams",
    pageTitle: "Cloverleaf | Build Better Teams",
    description: "Cloverleaf helps build great teams and improve employee engagement using existing employee data and assessments to provide insight into increased team productivity."
};
const GET_CURRENT_USER_INTERCOM_ID_QUERY = gql `
  query getCurrentUserIntercomId {
    currentUser {
      id
      email
      intercomId
    }
  }
`;
function CloverleafApp({ Component, pageProps, pageUrl, router, }) {
    const isAuthenticated = useAuth();
    const apolloClient = useApollo(pageProps);
    const store = useStore(pageProps?.initialReduxState);
    const loadAnalytics = React.useCallback(() => {
        /**
         * Fires segment analytics
         * analytics.page()
         */
        segment(pageProps.currentUser);
    }, [pageProps]);
    /**
     * https://nextjs.org/docs/api-reference/next/router#routerevents
     */
    React.useEffect(() => {
        // When the component is mounted, subscribe to router changes
        router.events.on("routeChangeStart", loadAnalytics);
        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            router.events.off("routeChangeStart", loadAnalytics);
        };
    }, [loadAnalytics, router.events]);
    React.useEffect(() => {
        // Initial analytic
        loadAnalytics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // const mixpanelKeyTest = "da7086a040d75e72037b93e7eaa2ae19";
    const mixpanelKeyProd = "c301b5e39baaa19e3a5ba5cdcf785b94";
    React.useEffect(() => {
        // Initialize mixpanel
        mixpanel.init(mixpanelKeyProd, {
            record_sessions_percent: 25,
            debug: false,
            track_pageview: true,
            persistence: "localStorage",
            record_mask_text_class: ["input", "textarea", "select"]
        });
    }, []);
    const pageLoadedRef = React.useRef();
    React.useEffect(() => {
        if (typeof window !== "undefined" && window.analytics) {
            if (pageLoadedRef.current !== window.location.pathname) {
                pageLoadedRef.current = window.location.pathname;
                window.analytics.page({
                    path: window.location.pathname
                });
            }
        }
    });
    /**
     * https://nextjs.org/docs/basic-features/layouts#per-page-layouts
     */
    const getLayout = Component.getLayout || (page => page);
    return (<ReduxProvider store={store}>
      <ApolloProvider client={apolloClient}>
        <EmployeeToolsProvider>
          <Head>
            <title>Cloverleaf | Build Better Teams</title>
            <meta name="viewport" content="initial-scale=1, width=device-width"/>

            {/* Twitter */}
            <meta name="twitter:card" content="summary" key="twcard"/>
            <meta name="twitter:creator" content={meta.twitterHandle} key="twhandle"/>
            <meta name="twitter:image" content={meta.twitterImage} key="twimage"/>

            {/* Open Graph */}
            <meta name="og:url" content={pageUrl}/>
            <meta property="og:image" content={meta.previewImage} key="ogimage"/>
            <meta property="og:site_name" content={meta.siteName} key="ogsitename"/>
            <meta property="og:title" content={meta.pageTitle} key="ogtitle"/>
            <meta property="og:description" content={meta.description} key="ogdesc"/>

            {/* Declare slack app  */}
            <meta name="slack-app-id" content="AAFJB47CN"/>

          </Head>
          <SentryUserManager />
          {typeof window !== "undefined" && <ToastMessages />}
          {typeof window !== "undefined" && <ConsentBanner />}
          {isAuthenticated && <TimezoneModal />}
          {getLayout(<Component {...pageProps} query={router?.query}/>)}
        </EmployeeToolsProvider>
      </ApolloProvider>
    </ReduxProvider>);
}
/**
 * Q: Why do we have this?
 * A: In order to use publicRuntimeConfig, automatic static optimization must be disabled.
 * This is not a green light to use getInitialProps elsewhere, which should be avoided.
 * @see https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
 */
CloverleafApp.getInitialProps = async function (appContext) {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);
    const authenticated = await checkAuthStatusFromContext(appContext.ctx);
    const apolloClient = initializeApollo(appContext);
    if (apolloClient && authenticated) {
        try {
            const { data } = await apolloClient.query({
                query: GET_CURRENT_USER_INTERCOM_ID_QUERY
            });
            return {
                ...appProps,
                pageProps: {
                    ...appProps.pageProps,
                    currentUser: data?.currentUser
                }
            };
        }
        catch (error) {
            Sentry.captureException(error, {
                level: "warning",
                extra: { message: "Error in _app getInitialProps" }
            });
        }
    }
    return { ...appProps };
};
const __Next_Translate__Page__191fcebe8cb__ = CloverleafApp;

    export default __appWithI18n(__Next_Translate__Page__191fcebe8cb__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  