import { publicRuntimeConfig } from "./config";

export const BASE_URL = publicRuntimeConfig.CLOVERLEAF_API_URL;

export const CHARGEBEE_SRC_URL = "https://js.chargebee.com/v2/chargebee.js";

export const COMING_SOON_URL = "https://cloverleaf.me/coming-soon";

export const CLOVERLEAF_BASE_URL = "https://cloverleaf.me";

export const CALENDAR_HELP_URL = "https://help.cloverleaf.me/en/articles/2707802-integrate-your-calendar-to-receive-insights-on-upcoming-meetings";

export const PRICING_PAGE_URL = "https://cloverleaf.me/pricing";

export const PRIVACY_PAGE_URL = "https://cloverleaf.me/privacy";

export const SUPPORT_PAGE_URL = "https://help.cloverleaf.me";

export const GROUPS_HELP_URL = "https://help.cloverleaf.me/en/collections/1401892-admin-dashboard#groups";

export const LEADS_BASE_URL = `${BASE_URL}/l/`;

// The max number of members on a team
export const FREE_TEAM_MEMBER_LIMIT = 5;

export const SEGMENT_WRITE_KEY = publicRuntimeConfig.SEGMENT_WRITE_KEY;
export const SEGMENT_PUBLIC_WRITE_KEY = publicRuntimeConfig.SEGMENT_PUBLIC_WRITE_KEY;

export const EVALUATOR_STATUS = {
  pending: "PENDING",
  completed: "COMPLETED",
  deleted: "DELETED",
  inProgress: "IN_PROGRESS",
};

export const PLANS = {
  free: "individual",
  team: publicRuntimeConfig.CHARGEBEE_TEAM_PLAN_NAME,
  teamPlan: "team_monthly",
  growth: "growth_monthly",
  reports: "reports",
  enterprise: publicRuntimeConfig.CHARGEBEE_ENTERPRISE_PLAN_NAME,
};

// As we create more plans, we will want them to be grouped by "type".
export const PLAN_TYPES = {
  [PLANS.free]: "individual",
  [PLANS.reports]: "individual",
  [PLANS.team]: "team",
  [PLANS.enterprise]: "enterprise",
};

export const ADDONS = {
  disc: "disc_single",
  reports: "reports",
  personalityReport: "16types-report",
  discReport: "disc-report",
  enneagramReport: "enneagram-report",
  instinctiveDrivesReport: "instinctive-drives-report",
  strengthscopeReport: "strengthscope",
};

export const ChargebeeEvents = {
  PAGE_VISITED: "cb.page_visited",
  SUCCESS: "cb.success",
  CLOSE: "cb.close",
  ERROR: "cb.error",
};

export const RESERVED_URL_PARAMETERS = ["active", "email", "firstName", "lastName", "role", "company"];

export const Feature = {
  CREATE_INDIVIDUAL_TEAM: "CREATE_INDIVIDUAL_TEAM",
  INCREASED_MEMBER_LIMIT: "INCREASED_MEMBER_LIMIT",
  ORGANIZATION_TEAM: "ORGANIZATION_TEAM",
  COACH_TIPS: "COACH_TIPS",
  DISC_REPORT: "DISC_REPORT",
  PERSONALITY_REPORT: "16TYPES_REPORT",
  ENNEAGRAM_REPORT: "ENNEAGRAM_REPORT",
  VIEW_ALL_PROFILES: "VIEW_ALL_PROFILES",
  CLIENT_NAVIGATOR: "CLIENT_NAVIGATOR",
  PREMIUM_CONTENT: "PREMIUM_CONTENT",
  CREATE_GROUP: "CREATE_GROUP",
};

// Corresponds to gql enum type
export const OrganizationOnboardingStatus = {
  COMPLETE: "COMPLETE",
  RUNNING: "RUNNING",
  PENDING: "PENDING",
};

export const Locale = {
  English: "en",
  Spanish: "es",
  German: "de",
};

export const ConnectedAccountType = {
  Google: "GOOGLE",
  Microsoft: "MICROSOFT",
  Slack: "SLACK",
  MS_Teams: "MS_TEAMS"
};

export const INTEGRATIONS = {
  MS_TEAMS: "MS_TEAMS",
  SLACK: "SLACK",
};

export const URL_FOCUSES = {
  REPORTS: "reports",
};
