import { faGoogle } from "@fortawesome/free-brands-svg-icons/faGoogle";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons/faMicrosoft";
import { faSlack } from "@fortawesome/free-brands-svg-icons/faSlack";
import { faSlackHash } from "@fortawesome/free-brands-svg-icons/faSlackHash";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faLoader as faLoaderSolid } from "@fortawesome/pro-duotone-svg-icons/faLoader";
import { faAngleRight as faAngleRightLight } from "@fortawesome/pro-light-svg-icons/faAngleRight";
import { faArrowCircleLeft as faArrowCircleLeftLight } from "@fortawesome/pro-light-svg-icons/faArrowCircleLeft";
import { faArrowCircleRight as faArrowCircleRightLight } from "@fortawesome/pro-light-svg-icons/faArrowCircleRight";
import { faArrowLeft as faArrowLeftLight } from "@fortawesome/pro-light-svg-icons/faArrowLeft";
import { faArrowRight as faArrowLeftRight } from "@fortawesome/pro-light-svg-icons/faArrowRight";
import { faArrowUpRightFromSquare as faArrowUpRightFromSquareLight } from "@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare";
import { faBan as faBanLight } from "@fortawesome/pro-light-svg-icons/faBan";
import { faBook as faBookLight } from "@fortawesome/pro-light-svg-icons/faBook";
import { faBriefcase as faBriefcaseLight } from "@fortawesome/pro-light-svg-icons/faBriefcase";
import { faChartLine as faChartLineLight } from "@fortawesome/pro-light-svg-icons/faChartLine";
import { faChevronDown as faChevronDownLight } from "@fortawesome/pro-light-svg-icons/faChevronDown";
import { faChevronLeft as faChevronLeftLight } from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import { faChevronRight as faChevronRightLight } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import { faChevronUp as faChevronUpLight } from "@fortawesome/pro-light-svg-icons/faChevronUp";
import { faCloudUpload as faCloudUploadLight } from "@fortawesome/pro-light-svg-icons/faCloudUpload";
import { faFloppyDisk as faFloppyDiskLight } from "@fortawesome/pro-light-svg-icons/faFloppyDisk";
import { faMoon as faMoonLight } from "@fortawesome/pro-light-svg-icons/faMoon";
import { faNewspaper as faNewspaperLight } from "@fortawesome/pro-light-svg-icons/faNewspaper";
import { faPaperPlane as faPaperPlaneLight } from "@fortawesome/pro-light-svg-icons/faPaperPlane";
import { faPlus as faPlusLight } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faPodcast as faPodcastLight } from "@fortawesome/pro-light-svg-icons/faPodcast";
import { faStar as faStarLight } from "@fortawesome/pro-light-svg-icons/faStar";
import { faSun as faSunLight } from "@fortawesome/pro-light-svg-icons/faSun";
import { faSunHaze as faSunHazeLight } from "@fortawesome/pro-light-svg-icons/faSunHaze";
import { faTimes as faTimesLight } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faVideo as faVideoLight } from "@fortawesome/pro-light-svg-icons/faVideo";
import { faUpRightFromSquare as faUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { faPen as faPenRegular } from "@fortawesome/pro-regular-svg-icons";
import { faPencil as faPencilRegular } from "@fortawesome/pro-regular-svg-icons";
import { faWandMagicSparkles as faWandMagicSparklesRegular } from "@fortawesome/pro-regular-svg-icons";
import { faAlarmClock as faAlarmClockRegular } from "@fortawesome/pro-regular-svg-icons";
import { faChartMixed as faChartMixedRegular } from "@fortawesome/pro-regular-svg-icons";
import { faHandsHoldingHeart as faHandsHoldingHeartRegular } from "@fortawesome/pro-regular-svg-icons";
import { faLightbulbOn as faLightbulbOnRegular } from "@fortawesome/pro-regular-svg-icons";
import { faFileCsv as faFileCsvRegular } from "@fortawesome/pro-regular-svg-icons";
import { faAnchor as faAnchorRegular } from "@fortawesome/pro-regular-svg-icons/faAnchor";
import { faArrowRight as faArrowRightRegular } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faArrowRightFromBracket as faArrowRightFromBracket } from "@fortawesome/pro-regular-svg-icons/faArrowRightFromBracket";
import { faArrows as faArrowsRegular } from "@fortawesome/pro-regular-svg-icons/faArrows";
import { faArrowsRotate as faArrowsRotateRegular } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { faArrowTurnUp as faArrowTurnUpRegular } from "@fortawesome/pro-regular-svg-icons/faArrowTurnUp";
import { faBalanceScale as faBalanceScaleRegular } from "@fortawesome/pro-regular-svg-icons/faBalanceScale";
import { faBolt as faBoltRegular } from "@fortawesome/pro-regular-svg-icons/faBolt";
import { faBookmark as faBookmarkRegular } from "@fortawesome/pro-regular-svg-icons/faBookmark";
import { faBookOpen as faBookOpenRegular } from "@fortawesome/pro-regular-svg-icons/faBookOpen";
import { faBrain as faBrainRegular } from "@fortawesome/pro-regular-svg-icons/faBrain";
import { faBug as faBugRegular } from "@fortawesome/pro-regular-svg-icons/faBug";
import { faBugSlash as faBugSlashRegular } from "@fortawesome/pro-regular-svg-icons/faBugSlash";
import { faBuilding as faBuildingRegular } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faBullhorn as faBullhornRegular } from "@fortawesome/pro-regular-svg-icons/faBullhorn";
import { faCamera as faCameraRegular } from "@fortawesome/pro-regular-svg-icons/faCamera";
import { faChartNetwork as faChartNetworkRegular } from "@fortawesome/pro-regular-svg-icons/faChartNetwork";
import { faCheck as faCheckRegular } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faCheckCircle as faCheckCircleRegular } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faCheckSquare as faCheckSquareRegular } from "@fortawesome/pro-regular-svg-icons/faCheckSquare";
import { faChessRook as faChessRookRegular } from "@fortawesome/pro-regular-svg-icons/faChessRook";
import { faCircle as faCircleRegular } from "@fortawesome/pro-regular-svg-icons/faCircle";
import { faCircleHalfStroke as faCircleHalfStrokeRegular } from "@fortawesome/pro-regular-svg-icons/faCircleHalfStroke";
import { faCircleQuestion as faCircleQuestionRegular } from "@fortawesome/pro-regular-svg-icons/faCircleQuestion";
import { faClipboardListCheck as faClipboardListCheckRegular } from "@fortawesome/pro-regular-svg-icons/faClipboardListCheck";
import { faClock as faClockRegular } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faCloudUpload as faCloudUploadRegular } from "@fortawesome/pro-regular-svg-icons/faCloudUpload";
import { faCommentAltEdit as faCommentAltEditRegular } from "@fortawesome/pro-regular-svg-icons/faCommentAltEdit";
import { faCopy as faCopyRegular } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faDollarSign as faDollarSignRegular } from "@fortawesome/pro-regular-svg-icons/faDollarSign";
import { faDownload as faDownloadRegular } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faEllipsisH as faEllipsisHRegular } from "@fortawesome/pro-regular-svg-icons/faEllipsisH";
import { faEnvelope as faEnvelopeRegular } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faExclamationCircle as faExclamationCircleRegular } from "@fortawesome/pro-regular-svg-icons/faExclamationCircle";
import { faExclamationTriangle as faExclamationTriangleRegular } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faExternalLink as faExternalLinkRegular } from "@fortawesome/pro-regular-svg-icons/faExternalLink";
import { faEyeSlash as faEyeSlashRegular } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import { faFileChartLine as faFileChartLineRegular } from "@fortawesome/pro-regular-svg-icons/faFileChartLine";
import { faFileExport as faFileExportRegular } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import { faFingerprint as faFingerprintRegular } from "@fortawesome/pro-regular-svg-icons/faFingerprint";
import { faGlobe as faGlobe } from "@fortawesome/pro-regular-svg-icons/faGlobe";
import { faHandshakeAlt as faHandshakeAltRegular } from "@fortawesome/pro-regular-svg-icons/faHandshakeAlt";
import { faHeart as faHeartRegular } from "@fortawesome/pro-regular-svg-icons/faHeart";
import { faInfoCircle as faInfoCircleRegular } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faList as faListRegular } from "@fortawesome/pro-regular-svg-icons/faList";
import { faLockAlt as faLockAltRegular } from "@fortawesome/pro-regular-svg-icons/faLockAlt";
import { faMagnet as faMagnetRegular } from "@fortawesome/pro-regular-svg-icons/faMagnet";
import { faMale as faMaleRegular } from "@fortawesome/pro-regular-svg-icons/faMale";
import { faMinusCircle as faMinusCircleRegular } from "@fortawesome/pro-regular-svg-icons/faMinusCircle";
import { faMountain as faMountainRegular } from "@fortawesome/pro-regular-svg-icons/faMountain";
import { faPaperPlane as faPaperPlaneRegular } from "@fortawesome/pro-regular-svg-icons/faPaperPlane";
import { faPenField as faPenFieldRegular } from "@fortawesome/pro-regular-svg-icons/faPenField";
import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faQuestionCircle as faQuestionCircleRegular } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faRandom as faRandomRegular } from "@fortawesome/pro-regular-svg-icons/faRandom";
import { faRulerTriangle as faRulerTriangleRegular } from "@fortawesome/pro-regular-svg-icons/faRulerTriangle";
import { faShieldAlt as faShieldAltRegular } from "@fortawesome/pro-regular-svg-icons/faShieldAlt";
import { faSitemap as faSitemapRegular } from "@fortawesome/pro-regular-svg-icons/faSitemap";
import { faSpinnerThird as faSpinnerThirdRegular } from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faStopwatch as faStopwatchRegular } from "@fortawesome/pro-regular-svg-icons/faStopwatch";
import { faSun as faSunRegular } from "@fortawesome/pro-regular-svg-icons/faSun";
import { faTasks as faTasksRegular } from "@fortawesome/pro-regular-svg-icons/faTasks";
import { faThumbsDown as faThumbsDownRegular } from "@fortawesome/pro-regular-svg-icons/faThumbsDown";
import { faThumbsUp as faThumbsUpRegular } from "@fortawesome/pro-regular-svg-icons/faThumbsUp";
import { faTimer as faTimerRegular } from "@fortawesome/pro-regular-svg-icons/faTimer";
import { faTimesSquare as faTimesSquareRegular } from "@fortawesome/pro-regular-svg-icons/faTimesSquare";
import { faTrashCan as faTrashCanRegular } from "@fortawesome/pro-regular-svg-icons/faTrashCan";
import { faUserLock as faUserLock } from "@fortawesome/pro-regular-svg-icons/faUserLock";
import { faUserPlus as faUserPlusRegular } from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import { faWrench as faWrenchRegular } from "@fortawesome/pro-regular-svg-icons/faWrench";
import { faClover as faCloverSolid } from "@fortawesome/pro-solid-svg-icons";
import { faSitemap as faSitemapSolid } from "@fortawesome/pro-solid-svg-icons";
import { faAddressBook as faAddressBookSolid } from "@fortawesome/pro-solid-svg-icons/faAddressBook";
import { faAlarmSnooze as faAlarmSnoozeSolid } from "@fortawesome/pro-solid-svg-icons/faAlarmSnooze";
import { faAngleDown as faAngleDownSolid } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faArrowCircleRight as faArrowCircleRightSolid } from "@fortawesome/pro-solid-svg-icons/faArrowCircleRight";
import { faArrowLeft as faArrowLeftSolid } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import { faArrowRight as faArrowRightSolid } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faBalanceScale as faBalanceScaleSolid } from "@fortawesome/pro-solid-svg-icons/faBalanceScale";
import { faBan as faBanSolid } from "@fortawesome/pro-solid-svg-icons/faBan";
import { faBars as faBarsSolid } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faBell as faBellSolid } from "@fortawesome/pro-solid-svg-icons/faBell";
import { faBolt as faBoltSolid } from "@fortawesome/pro-solid-svg-icons/faBolt";
import { faBookmark as faBookmarkSolid } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { faBuilding as faBuildingSolid } from "@fortawesome/pro-solid-svg-icons/faBuilding";
import { faBullseyePointer as faBullseyePointer } from "@fortawesome/pro-solid-svg-icons/faBullseyePointer";
import { faCalendarAlt as faCalendarAltSolid } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import { faCamera as faCameraSolid } from "@fortawesome/pro-solid-svg-icons/faCamera";
import { faCaretCircleDown as faCaretCircleDownSolid } from "@fortawesome/pro-solid-svg-icons/faCaretCircleDown";
import { faCaretDown as faCaretDownSolid } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft as faCaretLeftSolid } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight as faCaretRightSolid } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCaretUp as faCaretUpSolid } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faChartPie as faChartPieSolid } from "@fortawesome/pro-solid-svg-icons/faChartPie";
import { faCheck as faCheckSolid } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faCheckCircle as faCheckCircleSolid } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faChevronDown as faChevronDownSolid } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faChevronLeft as faChevronLeftSolid } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faChevronRight as faChevronRightSolid } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import { faChevronUp as faChevronUpSolid } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faCircle as faCircleSolid } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faCircle1 as faCircle1Solid } from "@fortawesome/pro-solid-svg-icons/faCircle1";
import { faCircle2 as faCircle2Solid } from "@fortawesome/pro-solid-svg-icons/faCircle2";
import { faCircle3 as faCircle3Solid } from "@fortawesome/pro-solid-svg-icons/faCircle3";
import { faCircleCheck as faCircleCheckSolid } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { faCircleLocationArrow as faCircleLocationArrowSolid } from "@fortawesome/pro-solid-svg-icons/faCircleLocationArrow";
import { faCircleQuestion as faCircleQuestionSolid } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCity as faCitySolid } from "@fortawesome/pro-solid-svg-icons/faCity";
import { faClipboard as faClipboardSolid } from "@fortawesome/pro-solid-svg-icons/faClipboard";
import { faClipboardListCheck as faClipboardListCheckSolid } from "@fortawesome/pro-solid-svg-icons/faClipboardListCheck";
import { faClock as faClockSolid } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faClockRotateLeft as faClockRotateLeftSolid } from "@fortawesome/pro-solid-svg-icons/faClockRotateLeft";
import { faComment as faCommentSolid } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faCommentExclamation as faCommentExclamationSolid } from "@fortawesome/pro-solid-svg-icons/faCommentExclamation";
import { faCopy as faCopySolid } from "@fortawesome/pro-solid-svg-icons/faCopy";
import { faDiagramVenn as faDiagramVennSolid } from "@fortawesome/pro-solid-svg-icons/faDiagramVenn";
import { faDollarSign as faDollarSignSolid } from "@fortawesome/pro-solid-svg-icons/faDollarSign";
import { faDoorOpen as faDoorOpenSolid } from "@fortawesome/pro-solid-svg-icons/faDoorOpen";
import { faDownload as faDownloadSolid } from "@fortawesome/pro-solid-svg-icons/faDownload";
import { faEdit as faEditSolid } from "@fortawesome/pro-solid-svg-icons/faEdit";
import { faEllipsisH as faEllipsisHSolid } from "@fortawesome/pro-solid-svg-icons/faEllipsisH";
import { faEnvelope as faEnvelopeSolid } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faEnvelopeOpenDollar as faEnvelopeOpenDollarSolid } from "@fortawesome/pro-solid-svg-icons/faEnvelopeOpenDollar";
import { faExclamation as faExclamationSolid } from "@fortawesome/pro-solid-svg-icons/faExclamation";
import { faExclamationCircle as faExclamationCircleSolid } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle as faExclamationTriangleSolid } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faExternalLink as faExternalLinkSolid } from "@fortawesome/pro-solid-svg-icons/faExternalLink";
import { faEye as faEyeSolid } from "@fortawesome/pro-solid-svg-icons/faEye";
import { faEyeSlash as faEyeSlashSolid } from "@fortawesome/pro-solid-svg-icons/faEyeSlash";
import { faFileAlt as faFileAltSolid } from "@fortawesome/pro-solid-svg-icons/faFileAlt";
import { faFileCertificate as faFileCertificateSolid } from "@fortawesome/pro-solid-svg-icons/faFileCertificate";
import { faFileChartLine as faFileChartLineSolid } from "@fortawesome/pro-solid-svg-icons/faFileChartLine";
import { faFileImport as faFileImportSolid } from "@fortawesome/pro-solid-svg-icons/faFileImport";
import { faFiles as faFilesSolid } from "@fortawesome/pro-solid-svg-icons/faFiles";
import { faFileUpload as faFileUploadSolid } from "@fortawesome/pro-solid-svg-icons/faFileUpload";
import { faFileUser as faFileUserSolid } from "@fortawesome/pro-solid-svg-icons/faFileUser";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons/faFilter";
import { faGear as faGearSolid } from "@fortawesome/pro-solid-svg-icons/faGear";
import { faGlobeAmericas as faGlobeAmericasSolid } from "@fortawesome/pro-solid-svg-icons/faGlobeAmericas";
import { faGridHorizontal as faGridHorizontalSolid } from "@fortawesome/pro-solid-svg-icons/faGridHorizontal";
import { faHandshake as faHandshakeSolid } from "@fortawesome/pro-solid-svg-icons/faHandshake";
import { faHeadSideBrain as faHeadSideBrainSolid } from "@fortawesome/pro-solid-svg-icons/faHeadSideBrain";
import { faHouse as faHouseSolid } from "@fortawesome/pro-solid-svg-icons/faHouse";
import { faHouseUser as faHouseUserSolid } from "@fortawesome/pro-solid-svg-icons/faHouseUser";
import { faImage as faImageSolid } from "@fortawesome/pro-solid-svg-icons/faImage";
import { faInboxOut as faInboxOutSolid } from "@fortawesome/pro-solid-svg-icons/faInboxOut";
import { faKey as faKeySolid } from "@fortawesome/pro-solid-svg-icons/faKey";
import { faLayerGroup as faLayerGroupSolid } from "@fortawesome/pro-solid-svg-icons/faLayerGroup";
import { faLightbulbOn as faLightbulbOnSolid } from "@fortawesome/pro-solid-svg-icons/faLightbulbOn";
import { faLink as faLinkSolid } from "@fortawesome/pro-solid-svg-icons/faLink";
import { faList as faListSolid } from "@fortawesome/pro-solid-svg-icons/faList";
import { faLock as faLockSolid } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faLockKeyhole as faLockKeyholeSolid } from "@fortawesome/pro-solid-svg-icons/faLockKeyhole";
import { faMailBulk as faMailBulkSolid } from "@fortawesome/pro-solid-svg-icons/faMailBulk";
import { faMinus as faMinusSolid } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faMinusCircle as faMinusCircleSolid } from "@fortawesome/pro-solid-svg-icons/faMinusCircle";
import { faNewspaper as faNewspaperSolid } from "@fortawesome/pro-solid-svg-icons/faNewspaper";
import { faNotEqual as faNotEqualSolid } from "@fortawesome/pro-solid-svg-icons/faNotEqual";
import { faPaperPlane as faPaperPlaneSolid } from "@fortawesome/pro-solid-svg-icons/faPaperPlane";
import { faPenField as faPenFieldSolid } from "@fortawesome/pro-solid-svg-icons/faPenField";
import { faPhone as faPhoneSolid } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faPlug as faPlugSolid } from "@fortawesome/pro-solid-svg-icons/faPlug";
import { faPlus as faPlusSolid } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faPlusCircle as faPlusCircleSolid } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { faPollPeople as faPollPeopleSolid } from "@fortawesome/pro-solid-svg-icons/faPollPeople";
import { faQuestion as faQuestionSolid } from "@fortawesome/pro-solid-svg-icons/faQuestion";
import { faQuestionCircle as faQuestionCircleSolid } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faRedoAlt as faRedoAltSolid } from "@fortawesome/pro-solid-svg-icons/faRedoAlt";
import { faSearch as faSearchSolid } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faSeedling as faSeedlingSolid } from "@fortawesome/pro-solid-svg-icons/faSeedling";
import { faShare as faShareSolid } from "@fortawesome/pro-solid-svg-icons/faShare";
import { faShareAll as faShareAllSolid } from "@fortawesome/pro-solid-svg-icons/faShareAll";
import { faShareSquare as faShareSquareSolid } from "@fortawesome/pro-solid-svg-icons/faShareSquare";
import { faShieldCheck as faShieldCheck } from "@fortawesome/pro-solid-svg-icons/faShieldCheck";
import { faSignOut as faSignOutSolid } from "@fortawesome/pro-solid-svg-icons/faSignOut";
import { faSlidersH as faSlidersHSolid } from "@fortawesome/pro-solid-svg-icons/faSlidersH";
import { faSlidersV as faSlidersVSolid } from "@fortawesome/pro-solid-svg-icons/faSlidersV";
import { faSort as faSortSolid } from "@fortawesome/pro-solid-svg-icons/faSort";
import { faSparkles as faSparklesSolid } from "@fortawesome/pro-solid-svg-icons/faSparkles";
import { faSpinner as faSpinnerSolid } from "@fortawesome/pro-solid-svg-icons/faSpinner";
import { faSquare as faSquareSolid } from "@fortawesome/pro-solid-svg-icons/faSquare";
import { faStamp as faStampSolid } from "@fortawesome/pro-solid-svg-icons/faStamp";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faStars as faStarsSolid } from "@fortawesome/pro-solid-svg-icons/faStars";
import { faStreetView as faStreetViewSolid } from "@fortawesome/pro-solid-svg-icons/faStreetView";
import { faSyncAlt as faSyncAltSolid } from "@fortawesome/pro-solid-svg-icons/faSyncAlt";
import { faTable as faTableSolid } from "@fortawesome/pro-solid-svg-icons/faTable";
import { faTag as faTagSolid } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faTasks as faTasksSolid } from "@fortawesome/pro-solid-svg-icons/faTasks";
import { faThumbsDown as faThumbsDownSolid } from "@fortawesome/pro-solid-svg-icons/faThumbsDown";
import { faThumbsUp as faThumbsUpSolid } from "@fortawesome/pro-solid-svg-icons/faThumbsUp";
import { faTimes as faTimesSolid } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faTimesCircle as faTimesCircleSolid } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { faToolbox as faToolboxSolid } from "@fortawesome/pro-solid-svg-icons/faToolbox";
import { faTrash as faTrashSolid } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { faTrashCan as faTrashCanSolid } from "@fortawesome/pro-solid-svg-icons/faTrashCan";
import { faTrashUndo as faTrashUndoSolid } from "@fortawesome/pro-solid-svg-icons/faTrashUndo";
import { faTruck as faTruckSolid } from "@fortawesome/pro-solid-svg-icons/faTruck";
import { faUnlink as faUnlinkSolid } from "@fortawesome/pro-solid-svg-icons/faUnlink";
import { faUnlock as faUnlockSolid } from "@fortawesome/pro-solid-svg-icons/faUnlock";
import { faUser as faUserSolid } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faUserChart as faUserChartSolid } from "@fortawesome/pro-solid-svg-icons/faUserChart";
import { faUserCheck as faUserCheckSolid } from "@fortawesome/pro-solid-svg-icons/faUserCheck";
import { faUserClock as faUserClockSolid } from "@fortawesome/pro-solid-svg-icons/faUserClock";
import { faUserFriends as faUserFriendsSolid } from "@fortawesome/pro-solid-svg-icons/faUserFriends";
import { faUserGear as faUserGearSolid } from "@fortawesome/pro-solid-svg-icons/faUserGear";
import { faUserLock as faUserLockSolid } from "@fortawesome/pro-solid-svg-icons/faUserLock";
import { faUserMinus as faUserMinusSolid } from "@fortawesome/pro-solid-svg-icons/faUserMinus";
import { faUserPlus as faUserPlusSolid } from "@fortawesome/pro-solid-svg-icons/faUserPlus";
import { faUsers as faUsersSolid } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { faUsersClass as faUsersClassSolid } from "@fortawesome/pro-solid-svg-icons/faUsersClass";
import { faUserShield as faUserShieldSolid } from "@fortawesome/pro-solid-svg-icons/faUserShield";
import { faUserSlash as faUserSlashSolid } from "@fortawesome/pro-solid-svg-icons/faUserSlash";
import { faUsersMedical as faUsersMedicalSolid } from "@fortawesome/pro-solid-svg-icons/faUsersMedical";
import { faUserTag as faUserTagSolid } from "@fortawesome/pro-solid-svg-icons/faUserTag";
import { faWandMagicSparkles as faWandMagicSparklesSolid } from "@fortawesome/pro-solid-svg-icons/faWandMagicSparkles";
import { faEllipsis as faEllipsisSharp } from "@fortawesome/sharp-solid-svg-icons/faEllipsis";
/**
 * Bug: NextJS and svg-core cause server and client hydration errors #19348
 * @see https://github.com/FortAwesome/Font-Awesome/issues/19348
 */
const { library } = require("@fortawesome/fontawesome-svg-core");

library.add(
  faAddressBookSolid,
  faAlarmSnoozeSolid,
  faAnchorRegular,
  faAngleRightLight,
  faAngleDownSolid,
  faArrowCircleLeftLight,
  faArrowCircleRightLight,
  faArrowCircleRightSolid,
  faArrowLeftLight,
  faArrowLeftRight,
  faArrowLeftSolid,
  faArrowRightFromBracket,
  faArrowRightRegular,
  faArrowRightSolid,
  faArrowTurnUpRegular,
  faArrowUpRightFromSquareLight,
  faArrowsRegular,
  faArrowsRotateRegular,
  faBalanceScaleRegular,
  faBalanceScaleSolid,
  faBarsSolid,
  faBellSolid,
  faBoltRegular,
  faBoltSolid,
  faBookLight,
  faBookOpenRegular,
  faBookmarkRegular,
  faBookmarkSolid,
  faBrainRegular,
  faBriefcaseLight,
  faBugRegular,
  faBugSlashRegular,
  faBuildingRegular,
  faBuildingSolid,
  faBullhornRegular,
  faCalendarAltSolid,
  faCalendarAltSolid,
  faCameraRegular,
  faCameraSolid,
  faCaretCircleDownSolid,
  faCaretDownSolid,
  faCaretUpSolid,
  faChartLineLight,
  faChartNetworkRegular,
  faChartPieSolid,
  faCheckCircleRegular,
  faCheckCircleSolid,
  faCheckRegular,
  faCheckSolid,
  faCheckSquareRegular,
  faChessRookRegular,
  faChevronDownLight,
  faChevronRightSolid,
  faChevronLeftSolid,
  faChevronUpLight,
  faChevronLeftLight,
  faChevronRightLight,
  faCircleHalfStrokeRegular,
  faCircleLocationArrowSolid,
  faCircleQuestionRegular,
  faCircleQuestionSolid,
  faCircleRegular,
  faCircleSolid,
  faCitySolid,
  faClipboardListCheckRegular,
  faClipboardListCheckSolid,
  faClipboardSolid,
  faClockRegular,
  faClockSolid,
  faCloudUploadLight,
  faFloppyDiskLight,
  faCloudUploadRegular,
  faCommentAltEditRegular,
  faCommentExclamationSolid,
  faCommentSolid,
  faCopyRegular,
  faCopySolid,
  faDiagramVennSolid,
  faDollarSignRegular,
  faDollarSignSolid,
  faDoorOpenSolid,
  faDownloadRegular,
  faDownloadSolid,
  faEditSolid,
  faEllipsisHRegular,
  faEllipsisHSolid,
  faEllipsisSharp,
  faBanSolid,
  faBanLight,
  faEnvelopeOpenDollarSolid,
  faEnvelopeRegular,
  faEnvelopeSolid,
  faExclamationCircleRegular,
  faExclamationCircleSolid,
  faExclamationSolid,
  faExclamationTriangleRegular,
  faExclamationTriangleSolid,
  faExternalLinkRegular,
  faExternalLinkSolid,
  faEyeSlashRegular,
  faEyeSlashSolid,
  faEyeSolid,
  faFileAltSolid,
  faFileCertificateSolid,
  faFileChartLineRegular,
  faFileChartLineSolid,
  faFileExportRegular,
  faFileImportSolid,
  faFileUploadSolid,
  faFileUserSolid,
  faFilesSolid,
  faFilterSolid,
  faFingerprintRegular,
  faGearSolid,
  faGlobe,
  faGlobeAmericasSolid,
  faGoogle,
  faGridHorizontalSolid,
  faHandshakeAltRegular,
  faHandshakeSolid,
  faHeadSideBrainSolid,
  faHeartRegular,
  faHouseSolid,
  faImageSolid,
  faInboxOutSolid,
  faInfoCircleRegular,
  faKeySolid,
  faLayerGroupSolid,
  faLightbulbOnSolid,
  faLinkSolid,
  faLinkedinIn,
  faListRegular,
  faListSolid,
  faLockAltRegular,
  faLockKeyholeSolid,
  faLockSolid,
  faMagnetRegular,
  faMailBulkSolid,
  faMaleRegular,
  faMicrosoft,
  faMinusSolid,
  faMinusCircleRegular,
  faMinusCircleSolid,
  faMoonLight,
  faMountainRegular,
  faNewspaperLight,
  faNewspaperSolid,
  faNotEqualSolid,
  faPaperPlaneLight,
  faPaperPlaneRegular,
  faPaperPlaneSolid,
  faPhoneSolid,
  faPlugSolid,
  faPlusCircleSolid,
  faPlusLight,
  faPlusRegular,
  faPlusSolid,
  faPodcastLight,
  faPollPeopleSolid,
  faQuestionCircleRegular,
  faQuestionCircleSolid,
  faQuestionSolid,
  faRandomRegular,
  faRedoAltSolid,
  faRulerTriangleRegular,
  faSearchSolid,
  faSeedlingSolid,
  faShareAllSolid,
  faShareSolid,
  faShareSquareSolid,
  faShieldAltRegular,
  faSpinnerThirdRegular,
  faSignOutSolid,
  faSlack,
  faSlackHash,
  faSlidersHSolid,
  faSlidersVSolid,
  faSortSolid,
  faSquareSolid,
  faStampSolid,
  faStarLight,
  faStarRegular,
  faStarSolid,
  faStarsSolid,
  faStopwatchRegular,
  faStreetViewSolid,
  faSunHazeLight,
  faSunLight,
  faSunRegular,
  faSyncAltSolid,
  faTableSolid,
  faTagSolid,
  faTasksRegular,
  faTasksSolid,
  faThumbsDownRegular,
  faThumbsDownSolid,
  faThumbsUpRegular,
  faThumbsUpSolid,
  faTimerRegular,
  faTimesCircleSolid,
  faTimesLight,
  faTimesSolid,
  faTimesSquareRegular,
  faToolboxSolid,
  faTrashCanRegular,
  faTrashCanSolid,
  faTrashSolid,
  faTrashUndoSolid,
  faTruckSolid,
  faTwitter,
  faUnlinkSolid,
  faUnlockSolid,
  faUpRightFromSquare,
  faUserChartSolid,
  faUserCheckSolid,
  faUserClockSolid,
  faClockRotateLeftSolid,
  faUserLock,
  faUserLockSolid,
  faUserFriendsSolid,
  faUserGearSolid,
  faUserMinusSolid,
  faUserPlusRegular,
  faUserPlusSolid,
  faUserShieldSolid,
  faUserSlashSolid,
  faUserSolid,
  faUserTagSolid,
  faUsersClassSolid,
  faUsersMedicalSolid,
  faUsersSolid,
  faHouseUserSolid,
  faVideoLight,
  faWandMagicSparklesSolid,
  faSparklesSolid,
  faWrenchRegular,
  faCloverSolid,
  faPenRegular,
  faPencilRegular,
  faPenFieldRegular,
  faShieldCheck,
  faPenFieldSolid,
  faCaretLeftSolid,
  faCaretRightSolid,
  faWandMagicSparklesRegular,
  faSpinnerSolid,
  faBullseyePointer,
  faSitemapRegular,
  faSitemapSolid,
  faAlarmClockRegular,
  faChartMixedRegular,
  faHandsHoldingHeartRegular,
  faLightbulbOnRegular,
  faCircle1Solid,
  faCircle2Solid,
  faCircle3Solid,
  faCircleCheckSolid,
  faFileCsvRegular,
  faLoaderSolid,
  faChevronUpSolid,
  faChevronDownSolid,
);
