import { Locale } from "@/lib/constants";

export const STRENGTHSFINDER_URL = "https://www.gallupstrengthscenter.com/product/en-us/10108/top-5-cliftonstrengths-access?utm_source=cloverleaf";

export const STRENGTHSFINDER_REDEEM_URL = "https://my.gallup.com/_Home/RedeemAccessCode";

export const ASSESSMENT = {
  STRENGTHS: "STRENGTHS",
  DISC: "DISC",
  PERSONALITY: "PERSONALITY",
  CULTURE: "CULTURE",
  VIA: "VIA",
  DRIVINGFORCES: "DRIVINGFORCES",
  MOTIVATINGVALUES: "MOTIVATINGVALUES",
  ENNEAGRAM: "ENNEAGRAM",
  ROLE_ALIGNMENT: "ROLE_ALIGNMENT",
  SELF_ROLE_ALIGNMENT: "SELF_ROLE_ALIGNMENT",
  INSTINCTIVEDRIVES: "INSTINCTIVEDRIVES",
  ENERGYRHYTHM: "ENERGYRHYTHM",
  STRENGTHSCOPE: "STRENGTHSCOPE",
  HDBI: "HDBI",
};

export const ASSESSMENT_BY_ID = {
  STRENGTHS: 1,
  DISC: 2,
  PERSONALITY: 4,
  CULTURE: 5,
  VIA: 6,
  DRIVINGFORCES: 7,
  MOTIVATINGVALUES: 8,
  ENNEAGRAM: 9,
  SIXTEENPF: 10,
  INSTINCTIVEDRIVES: 11,
  ENERGYRHYTHM: 12,
  STRENGTHSCOPE: 13,
  HBDI: 14,
  COACHING_IMPACT: 15,
};

export const ASSESSMENTS_CONFIG_IDS = {
  "16-types": "personality",
  "culture-pulse": "culture",
  "disc": "disc",
  "energy-rhythm": "energyRhythm",
  "enneagram": "enneagram",
  "instinctive-drives": "instinctiveDrives",
  "strengths-finder": "strengths",
  "motivating-values": "motivatingvalues",
  "strengthscope": "strengthscope",
  "via": "via",
}

export const ConfigurationSetting = {
  DISABLED: "DISABLED",
  ENABLED: "ENABLED",
  VISIBLE: "VISIBLE",
  REQUIRED: "REQUIRED",
  HIDDEN: "HIDDEN",
};

// Configuration Constant - should match configurationId names from the backend.
export const Configuration = {
  CLIENT_NAVIGATOR: "CLIENT_NAVIGATOR",
  CALENDAR: "calendar",
  COACH_TIPS: "COACH_TIPS",
  PREMIUM_CONTENT: "PREMIUM_CONTENT",
  CREATE_GROUP: "CREATE_GROUP",
  ORGANIZATION_TEAM: "ORGANIZATION_TEAM",
  INDIVIDUAL_TEAM: "INDIVIDUAL_TEAM",
  INCREASED_MEMBER_LIMIT: "INCREASED_MEMBER_LIMIT",
  SIXTEENTYPES_REPORT: "16TYPES_REPORT",
  DISC_REPORT: "DISC_REPORT",
  ENNEAGRAM_REPORT: "ENNEAGRAM_REPORT",
  CLEAVER_REPORT: "cleaver_report",
  UPLOAD_AVATAR: "upload-avatar",
  DRIVING_FORCES: "12-driving-forces",
  SIXTEEN_TYPES: "16-types",
  CULTURE_PULSE: "culture-pulse",
  DISC: "disc",
  ENNEAGRAM: "enneagram",
  MOTIVATINGVALUES: "motivating-values",
  STRENGTHSFINDER: "strengths-finder",
  VIA: "via",
  CUSTOM_NOTIFICATION_SCREEN: "custom_notification_screen",
  INSTINCTIVEDRIVES: "instinctive-drives",
  ENERGYRHYTHM: "energy-rhythm",
  INSTINCTIVE_DRIVES_REPORT: "INSTINCTIVE_DRIVES_REPORT",
  REPORT_BIO: "report-bio",
  STRENGTHSCOPE_REPORT: "strengthscope-report",
  DIRECTORY: "directory",
  RESOURCE_FEED: "resource-feed",
  TEAM_THINKING_STYLES: "team-thinking-styles",
  TEAM_ROLES: "team-roles",
  INVITE_MEMBER: "invite-member",
  DISCOVERABLE_GROUPS: "discoverable-group",
  DISCOVERABLE_TEAMS: "discoverable-team",
  INSIGHT_SEARCH: "insight-search",
  MANAGE_PARTNER_ORGANIZATIONS: "manage-partner-organizations",
  TEAM_DASHBOARD: "team-dashboard",
  HBDI: "hbdi",
  STRENGTHSCOPE: "strengthscope",
  SKILLS: "skills",
  PERSONALITY_FACTORS: "16pf",
  COACHING_IMPACT_SURVEY: "coaching-impact-survey",
  SPANISH_DISC_REPORT: "spanish-disc-report",
  EXTERNAL_INTEGRATIONS: "external-integrations",
  ALLOW_PARTNER_ORGANIZATIONS: "allow-partner-organizations",
  CANDIDATES: "candidates",
  LEADS: "leads",
  MANAGE_PARTNER_ORGANIZATIONS_FULL: "manage-partner-organizations-full",
  ORGANIZATION_REPORTS: "organization-reports",
  PROFILE_COMPARISON: "profile-comparison",
  RESOURCE_HUB: "allbound",
  ROLE_ALIGNMENT: "role-alignment",
  SELF_ROLE_ALIGNMENT: "self-role-alignment",
  STRENGTHSCOPE_TEAM: "strengthscope-team",
  TIP_SHARING: "tip-sharing",
  USER_REFLECTION: "user-reflection",
  PROMO_LINKS: "promotion-links",
};

// User facing labels for the assessment names
export const AssessmentLabelByName = {
  [ASSESSMENT.STRENGTHS]: "CliftonStrengths®",
  [ASSESSMENT.DISC]: "DISC",
  [ASSESSMENT.PERSONALITY]: "16 Types",
  [ASSESSMENT.CULTURE]: "Culture Pulse",
  [ASSESSMENT.VIA]: "VIA",
  [ASSESSMENT.DRIVINGFORCES]: "Driving Forces",
  [ASSESSMENT.MOTIVATINGVALUES]: "Motivating Values",
  [ASSESSMENT.ENNEAGRAM]: "Enneagram",
  [ASSESSMENT.INSTINCTIVEDRIVES]: "Instinctive Drives",
  [ASSESSMENT.ENERGYRHYTHM]: "Energy Rhythm",
  [ASSESSMENT.STRENGTHSCOPE]: "Strengthscope",
};

export const LocaleSupportedAssessments = {
  [Locale.Spanish]: [
    ASSESSMENT.PERSONALITY,
    ASSESSMENT.DISC,
    ASSESSMENT.CULTURE,
  ],
  [Locale.German]: [
    ASSESSMENT.PERSONALITY,
    ASSESSMENT.DISC,
  ],
};

export const INSIGHT_LABELS = {
  communication: "Communication Styles",
  general: "General",
  development: "Development",
  leadership: "Leadership",
  conflict: "Conflict Triggers",
  motivation: "Motivation",
  persuasion: "Persuasion",
  workstyle: "Work Style",
  change: "Change",
};

export const UserPrivacy = {
  ORGANIZATION: "ORGANIZATION",
  TEAM: "TEAM",
  EVERYONE: "EVERYONE",
};

export const EVENT = {
  AssessmentStarted: { name: "Assessment Started" },
  AssessmentInstructionsCompleted: { name: "Assessment Instructions Completed" },
  AssessmentCompleted: { name: "Assessment Completed" },
  AssessmentCompletedConfirmationClicked: { name: "Assessment Completed Confirmation Clicked" },
  AssessmentPageLoaded: { name: "Assessment Page Loaded" },
  SurveyOppurtunityChecked: { name: "Survey Opportunity Checked" },
  SurveyStarted: { name: "Survey Started" },
  SurveyInstructionsCompleted: { name: "Survey Instructions Completed" },
  SurveyInterestsCompleted: { name: "Survey Interests Completed" },
  SurveyCompleted: { name: "Survey Completed" },
  SurveyCompletedConfirmationClicked: { name: "Survey Completed Confirmation Clicked" },
  SurveyPageLoaded: { name: "Survey Page Loaded" },
  SurveySkipped: { name: "Survey Skipped" },
  SurveyExited: { name: "Survey Exited" },
  AccountCreated: {
    name: "Account Created",
    props: {
      type: {
        INVITED: "invited",
        ORGANIC: "organic",
        CANDIDATE: "candidate",
      },
    },
  },
  TeamCreated: { name: "Team Created" },
  TeamTrialStarted: { name: "Team Trial Started" },
  UserStatsCardViewed: {
    name: "Baseball Card Viewed",
    props: {
      view: {
        scores: "scores",
        userDetails: "user details",
      },
    },
  },
  ContentViewed: {
    name: "Content Viewed",
    props: {
      action: {
        open: "open",
        close: "close",
      },
      target: {
        team: "team",
        self: "self",
        teammate: "teammate",
      },
      page: {
        team: "team",
        self: "self",
        profile: "profile",
        results: "results",
        dailyCoaching: "daily-coaching",
        coachingView: "coaching-view",
      },
      location: {
        teamDashboard: "team-dashboard",
      },
      component: {
        team16Types: "Team 16 Types",
        teamBestTimeOfDay: "Team Best Time Of Day",
        teamInstinctiveDrives: "Team Instinctive Drives",
        teamCulturePulse: "Team Culture Pulse",
        teamDisc: "Team Disc",
        teamEnergyRhythm: "Team Energy Rhythm",
        teamEnneagram: "Team Enneagram",
        teamMotivatingValues: "Team Motivating Values",
        teamRoles: "Team Roles",
        teamRolesGrid: "Team Roles Grid",
        teamStrengths: "Team Strengths",
        teamStrengthscopeClearStrengths: "Team Strengthscope Clear Strengths",
        teamStrengthscopeFree5Members: "Team Strengthscope Free 5 Members",
        teamThinkingStylesComparison: "Team Thinking Styles Comparison",
        teamViaStrengths: "Team Via Strengths",
        teamThinkingStyle: "Team Thinking Style",
        teamStrengthsChart: "Team Strengths Chart",
        teamViaChart: "Team Via Chart",
        selfEnneagram: "Self Enneagram",
        selfEnergyRhythm: "Self Energy Rhythm",
        selfSixteenTypes: "Self 16 Types",
        selfStrengthscope: "Self Strengthscope",
        selfInstinctiveDrives: "Self Instinctive Drives",
        selfDisc: "Self Disc",
        selfStrengthFinder: "Self Strength Finder",
        selfVia: "Self VIA",
        selfCulturePulse: "Self Culture Pulse",
        selfMotivatingValues: "Self Motivating Values",
        selfHDBI: "Self HDBI",
        profileEnneagram: "Profile Enneagram",
        profileEnergyRhythm: "Profile Energy Rhythm",
        profileSixteenTypes: "Profile 16 Types",
        profileStrengthscope: "Profile Strengthscope",
        profileInstinctiveDrives: "Profile Instinctive Drives",
        profileDisc: "Profile Disc",
        profileStrengthsFinder: "Profile StrengthsFinder",
        profileVia: "Profile VIA",
        profileCulturePulse: "Profile Culture Pulse",
        profileMotivatingValues: "Profile Motivating Values",
        profileThinkingStyleComparison: "Profile Thinking Style Comparison",
        resultsEnneagram: "Results Enneagram",
        resultsStrengthscope: "Results Strengthscope",
        resultsCulturePulse: "Results Culture Pulse",
        resultsDisc: "Results DISC",
        resultsVia: "Results VIA",
        resultsSixteenTypes: "Results 16 Types",
        resultsEnergyRhythm: "Results EnergyRhythm",
        resultsInstinctiveDrives: "Results Instinctive Drives",
        resultsMotivatingValues: "Results Motivating Values",
        baseballCard: "Baseball Card",
        baseballCardStrengthsFinder: "Baseball Card Strengths Finder",
        baseballCardVia: "Baseball Card VIA",
        baseballCardSixteenTypes: "Baseball Card Sixteen Types",
        baseballCardCulturePulse: "Baseball Card Culture Pulse",
        baseballCardMotivatingValues: "Baseball Card Motivating Values",
        baseballCardDisc: "Baseball Card Disc",
        baseballCardDrivingForces: "Baseball Card Driving Forces",
        baseballCardEnneagram: "Baseball Card Enneagram",
        baseballCardInstinctiveDrives: "Baseball Card Instinctive Drives",
        baseballCardEnergyRhythm: "Baseball Card Energy Rhythm",
        baseballCardStrengthscope: "Baseball Card Strengthscope",
        exploreThinkingStylesComparison: "Explore Thinking Styles Comparison",
      },
      contentType: {
        select: "select",
        titleTrait: "Title Trait",
        trait: "Trait",
        traitNugget: "Trait Nugget",
      },
      element: {
        team16TypesComparisonWheel: "Team 16 Types Comparison Wheel",
        teamDiscWheel: "Team Disc Wheel",
        teamEnneagramWheel: "Team Enneagram Wheel",
        teamEnergyRhythmPieChart: "Team Energy Rhythm Pie Chart",
        teamBestTimeOfDayGraph: "Team Best Time Of Day Graph",
        teamStrengthscope: "Team Strengthscope",
        teamInstinctiveDrivesChart: "Team Instinctive Drives Chart",
        teamCulturePulse: "Team Culture Pulse",
        selfDiscWheel: "Self Disc Wheel",
      },
    },
  },
  InsightViewed: {
    name: "Insight Viewed",
    props: {
      // NOTE: Channel should match the `coachTipRated` event channels as well
      channel: {
        insightBoxDeprecated: "insight box", // Deprecated, use meCoachingBox or profileCoachingBox
        UserStatsCard: "baseball card insights",
        meCoachingBox: "me-coaching-box",
        profileCoachingBox: "profile-coaching-box",
        dailyCoaching: "daily-coaching",
        insightSearch: "insight-search",
        assessmentResults: "assessment-results",
        meetingPrep: "meeting-prep",
      },
      target: {
        self: "self",
        team: "team",
      },
      insightType: {
        group: "group",
      },
      button: {
        toggle: "toggle",
      },
    },
  },
  // This event is tracked Server-side not client-side, but the channel info is passed from the client.
  CoachTipRated: {
    name: "Coach Tip Rated",
    props: {
      // These should largely match the API-side INSIGHT_CHANNEL constant
      channel: {
        insightBoxDeprecated: "insight box", // Deprecated, use meCoachingBox or profileCoachingBox
        resultsPageDeprecated: "results-page", // Deprecated, use ActivateCoaching
        activateCoaching: "activate-coaching", // Replaced the deprecated resultsPageDeprecated channel.
        meCoachingBox: "me-coaching-box",
        profileCoachingBox: "profile-coaching-box",
        calendarBox: "calendar-box",
        dailyCoaching: "daily-coaching",
        insightSearch: "insight-search",
        assessmentResults: "assessment-results",
      },
      target: {
        self: "self",
        team: "team",
      },
    },
  },
  AssessmentEntered: {
    name: "Assessment Entered",
    props: {
      assessmentName: {
        disc: ASSESSMENT.DISC,
        strengthsFinder: ASSESSMENT.STRENGTHS,
        personality: ASSESSMENT.PERSONALITY,
        culturePulse: ASSESSMENT.CULTURE,
        via: ASSESSMENT.VIA,
        motivatingValues: ASSESSMENT.MOTIVATINGVALUES,
        enneagram: ASSESSMENT.ENNEAGRAM,
      },
    },
  },
  ReportPaymentStarted: {
    name: "Report Payment Started",
  },
  ReportPaymentCompleted: {
    name: "Report Payment Completed",
  },
  InsightShared: {
    name: "Insight Shared",
  },
  CalendarSnoozed: {
    name: "Calendar Snoozed",
    props: {
      type: {
        event: "event",
        participant: "participant",
        allParticipants: "all participants",
      },
    },
  },
  CalendarRefreshed: {
    name: "Calendar Manually Refreshed",
  },
  CalendarPagination: {
    name: "Calendar Pagination Clicked",
    props: {
      direction: {
        next: "next",
        today: "today",
        back: "back",
      },
    },
  },
  AttendeeToggled: {
    name: "Attendee Toggled",
    props: {
      channel: {
        calendarWidget: "calendar-widget",
      }
    }
  },
  CalendarEventToggled: {
    name: "Calendar Event Toggled",
  },
  InviteSent: {
    name: "Invite Sent",
    props: {
      type: {
        calendar: "calendar", // Team and org invites currently tracked on the server
      },
      channel: {
        adminDashboard: "ADMIN_DASHBOARD",
        calendar: "CALENDAR",
        dailyCoaching: "DAILY_COACHING",
        myDashboard: "MY_DASHBOARD",
        newTeam: "NEW_TEAM",
        organizationDirectory: "ORGANIZATION_DIRECTORY",
        sidebar: "SIDEBAR",
        teamDashboard: "TEAM_DASHBOARD",
        teamSettings: "TEAM_SETTINGS",
        trialActivation: "TRIAL_ACTIVATION",
        userDirectory: "USER_DIRECTORY",
        insightSearch: "INSIGHT_SEARCH",
        userSettings: "USER_SETTINGS",
      }
    },
  },
  TeamCoachingSettingChanged: {
    name: "Team Coaching Setting Changed",
  },
  TeamSendInvitesClicked: {
    name: "Team Send Invites Clicked",
  },
  TeamSkipInvitesClicked: {
    name: "Team Skip Invites Clicked",
  },
  TaskMoreInfoClicked: {
    name: "Task More Info Clicked",
  },
  TaskClicked: {
    name: "Task Clicked",
  },
  NotificationBellClicked: {
    name: "Notification Bell Clicked",
  },
  TeamRoleToggled: {
    name: "Team Role Toggled",
  },
  TeamRoleGridToggled: {
    name: "Team Role Grid Toggled",
  },
  IncompleteIndicatorClicked: {
    name: "Incomplete Indicator Clicked",
  },
  ClientNavigatorDemoRequested: {
    name: "Client Navigator Demo Requested",
  },
  ResourceLinkClicked: {
    name: "Resource Link Clicked",
  },
  CreateTeamClicked: {
    name: "Create Team Clicked",
  },
  JoinATeamClicked: {
    name: "Join A Team Clicked",
  },
  BrowseTeamClicked: {
    name: "Browse Existing Teams Clicked",
  },
  AddTeamMemberSelected: {
    name: "Add Team Member Selected",
    props: {
      type: {
        member: "member",
        admin: "admin",
      },
    },
  },
  TeamOwnerEmailed: {
    name: "Team Owner Emailed",
  },
  PricingLinkClicked: {
    name: "Pricing Link Clicked",
  },
  CheckoutCompleted: {
    name: "Checkout Completed",
  },
  CheckoutStarted: {
    name: "Checkout Started",
  },
  ResultsTeamButtonClicked: {
    name: "Results Team Button Clicked",
  },
  ResultsMeButtonClicked: {
    name: "Results Me Button Clicked",
  },
  ResultsMarketingClicked: {
    name: "Results Show Marketing Clicked",
  },
  CreateTeamButtonClicked: {
    name: "Create Team Button Clicked",
  },
  CreateTeamInvitesAdded: {
    name: "Create Team Invites Added",
  },
  ResultsFinishButtonClicked: {
    name: "Results Finish Button Clicked",
  },
  InstinctiveDrivesInterimReportDownloaded: {
    name: "Instinctive Drives Interim Report Downloaded",
  },
  InstinctiveDrivesRetakeAssessmentClicked: {
    name: "Instinctive Drives Retake Assessment Clicked",
  },
  InstinctiveDrivesResourcesClicked: {
    name: "Instinctive Drives Resources Clicked",
  },
  StrengthscopeOAuthClicked: {
    name: "Strength Scope Oauth Flow Started",
  },
  StrengthscopeVideoCoursesClicked: {
    name: "Strengthscope Video Courses Clicked",
  },
  StrengthscopeHubLinkOpened: {
    name: "Strengthscope Hub Link Opened",
  },
  ExperimentViewed: {
    name: "Experiment Viewed",
  },
  SSOSigninClicked: {
    name: "SSO Signin Clicked",
  },
  LanguageChange: {
    name: "Language Changed",
  },
  ResultsComparisonChanged: {
    name: "Results Comparison Changed",
  },
  DirectoryUserClicked: {
    name: "Directory User Clicked",
  },
  DirectorySearchStarted: {
    name: "Directory Search Started",
  },
  ViewProfileBannerLinkClicked: {
    name: "View Profile Banner Link Clicked",
  },
  ViewProfileBannerAvatarClicked: {
    name: "View Profile Banner Avatar Clicked",
  },
  ComparisonAssessmentButtonClicked: {
    name: "Comparison Assessment Button Clicked",
  },
  CopyShareLinkButtonClicked: {
    name: "Copy Share Link Button Clicked",
    props: {
      channel: {
        insightSearch: "insight-search",
      },
      type: {
        coachTipLink: "coach-tip-link"
      },
    },
  },
  ShareProfileClicked: {
    name: "Share Profile Clicked",
    props: {
      location: {
        dashboardBanner: "banner button",
        stickyHeader: "header prompt",
      },
    },
  },
  ShareProfilePrivacySettingsChanged: {
    name: "Privacy Setting Changed",
  },
  CollapsibleNuggetClicked: {
    name: "Collapsible Nugget Clicked",
  },
  SwitchTeamClicked: {
    name: "Switch Team Clicked",
  },
  ViewTeamMembersClicked: {
    name: "View Team Members Clicked",
  },
  TeamSettingsClicked: {
    name: "Team Settings Clicked",
  },
  CoachingEmailFrequencyChanged: {
    name: "Coaching Email Frequency Changed",
  },
  CoachingEmailTimeOfDayChanged: {
    name: "Coaching Email Time Of Day Changed",
  },
  TakeAssessmentCTAClicked: {
    name: "Take Assessment CTA Clicked",
    props: {
      location: {
        teamDashboard: "team dashboard",
      },
    },
  },
  ViewResultsCTAClicked: {
    name: "View Results CTA Clicked",
    props: {
      location: {
        teamDashboard: "team dashboard",
      },
    },
  },
  InviteNowCTAClicked: {
    name: "Invite Now CTA Clicked",
  },
  TeamCreatedModalMeLinkClicked: {
    name: "Team Created Modal Me Link Clicked",
  },
  TrialSkipCalendarIntegration: {
    name: "Trial Skip Calendar Integration",
  },
  StartTrialCarouselCreateTeamClicked: {
    name: "Trial Carousel - Create Team Clicked",
  },
  StartTrialCarouselGoToMeDashboardClicked: {
    name: "Trial Carousel - No Thanks Clicked",
  },
  StartTrialCarouselLearnMoreClicked: {
    name: "Trial Carousel - Learn More Clicked",
  },
  TrialBannerPurchaseClicked: {
    name: "Trial Banner - Purchase Clicked",
  },
  TrialBannerViewPlanClicked: {
    name: "Trial Banner - View Plan Clicked",
  },
  TeamInviteLinkCopied: {
    name: "Team Invite Link Copied",
  },
  TeamInviteLinkCreated: {
    name: "Team Invite Link Created",
  },
  TeamInviteLinkDeleted: {
    name: "Team Invite Link Deleted",
  },
  OrgInviteLinkCopied: {
    name: "Organization Invite Link Copied",
  },
  OrgInviteLinkCreated: {
    name: "Organization Invite Link Created",
  },
  OrgInviteLinkDeleted: {
    name: "Organization Invite Link Deleted",
  },
  DemoTeamOfferClosed: {
    name: "Demo Team Offer Closed",
  },
  ViewDemoTeamClicked: {
    name: "View Demo Team Clicked",
  },
  CarouselPaginationClicked: {
    name: "Carousel Pagination Clicked",
  },
  CoachingFeedbackInviteOthersClicked: {
    name: "Coaching Feedback Invite Others Clicked",
  },
  CoachingFeedbackViewMorePersonalCoachTipsClicked: {
    name: "View More Personal Coach Tips Clicked",
    props: {
      location: {
        bringInThePeople: "Bring in the people",
        whatToDoNext: "What do you want to do next",
      },
    },
  },
  CoachingFeedbackViewMoreTeamCoachTipsClicked: {
    name: "View More Team Coach Tips Clicked",
  },
  UpgradeMeDashboardAssessmentButtonStarted: {
    name: "Upgrade From Me Dashboard Assessment Started",
  },
  UpgradeMeDashboardAssessmentButtonCompleted: {
    name: "Upgrade From Me Dashboard Assessment Completed",
  },
  JoinOrganizationClicked: {
    name: "Join Organization Clicked",
    props: {
      location: {
        joinOrganization: "join-organization",
      },
    },
  },
  SkipJoinOrganizationClicked: {
    name: "Skip Join Organization Clicked",
  },
  Pages: {
    dailyCoaching: "daily-coaching",
  },
  UnsubmittedFeedback: {
    name: "Unsubmitted Feedback",
  },
  NetworkUpdated: {
    name: "Added Users to Coaching Network",
    channel: {
      networkPage: "Network Page",
      profile: "Profile",
      onboarding: "Onboarding",
    }
  },
  InsightSearch: {
    SearchExecuted: {
      name: "SearchExecuted",
    },
    DropdownUsed: {
      name: "DropdownUsed",
      interactionType: {
        keyboard: "keyboard",
        hover: "hover",
      }
    },
    SearchHistoryCleared: {
      name: "SearchHistoryCleared",
      clearType: {
        singleQuery: "single query",
        fullHistory: "full history"
      },
    },
    SearchAssessmentSuggested: {
      name: "SearchAssessmentSuggested",
    },
    AssessmentSuggestionClicked: {
      name: "AssessmentSuggestionClicked",
    },
    SuggestedQueryClicked: {
      name: "Suggested query clicked",
    },
    page: {
      myDashboard: "My Dashboard",
      profile: "Profile",
    },
    channel: {
      insightSearch: "insight-search",
      universalSearch: "universal-search",
      assessmentResults: "assessment-results",
    },
  },
  DataTableLayoutToggle: {
    name: "DataTableLayoutToggle",
    props: {
      type: {
        DisplayOrganizations: "Display Organizations",
      },
    },
  },
  ShareTipOpened: {
    name: "ShareTipOpened",
    props: {
      channel: {
        coachTip: "coach-tip",
      },
      platform: {
        email: "email",
        msTeams: "ms-teams",
      },
    },
  },
  CoachTipLinkCopied: {
    name: "CoachTipLinkCopied",
    props: {
      page: {
        myDasboard: "my dashboard",
        profile: "profile",
      },
      channel: {
        insightSearch: "insight-search",
        profileBox: "profile-box",
        dailyCoaching: "daily-coaching",
      },
    },
  },
  ChooseAssessment: {
    name: "ChooseAssessmentClicked",
    page: {
      myDashboard: "my dashboard",
    },
  },
  CoachTipSaved: {
    name: "CoachTipSaved",
    props: {
      page: {
        myDashboard: "my dashboard",
        mySavedTips: "my saved tips",
        profile: "profile",
      },
      platform: {
        email: "email",
        msTeams: "ms-teams",
      },
      channel: {
        dailyCoaching: "daily-coaching",
        insightSearch: "insight-search",
        mySavedTips: "my saved tips",
        profileBox: "profile-box",
      },
    }
  },
  CoachTipUnsaveClicked: {
    name: "CoachTipUnsaveClicked",
    props: {
      page: {
        myDashboard: "my dashboard",
        mySavedTips: "my saved tips",
        profile: "profile",
      },
      channel: {
        dailyCoaching: "daily-coaching",
        insightSearch: "insight-search",
        mySavedTips: "my saved tips",
        profileBox: "profile-box",
      },
    },
  },
  ReflectionOpened: {
    name: "Reflection Opened",
    props: {
      channel: {
        dailyCoaching: "daily-coaching",
        insightSearch: "insight-search",
        reflections: "reflections",
        savedTips: "saved-tips",
        sharePage: "share-page",
        assessmentResults: "assessment-results",
      },
      platform: {
        email: "email",
        msTeams: "ms-teams",
      },
    },
    target: {
      self: "self",
    }
  },
  EditReflectionClicked: {
    name: "EditReflectionClicked",
    props: {
      channel: {
        reflections: "reflections",
      },
    },
  },
  ReflectionClosed: {
    name: "Reflection Closed",
    props: {
      channel: {
        dailyCoaching: "daily-coaching",
        insightSearch: "insight-search",
        reflections: "reflections",
        savedTips: "saved-tips",
        sharePage: "share-page",
      },
    },
  },
  ReflectionCreated: {
    name: "Reflection Created",
  },
  DailyCoaching: {
    name: "Daily Coaching",
    props: {
      action: {
        opened: "opened",
        closed: "closed",
        next: "next",
        previous: "previous",
        done: "done",
      },
    },
  },
  MetricClicked: {
    name: "Metric Clicked",
  },
  TableExported: {
    name: "Table Exported",
  },
  CalendarConnected: {
    name: "Calendar Connected",
    props: {
      channel: {
        calendarIntegrationPage: "calendar-integration-page",
      },
      source: {
        onboarding: "onboarding",
        settings: "settings",
        meetingPrep: "meeting-prep",
      }
    }
  },
  AttemptingToConnectCalendarClicked: {
    name: "Attempting to Connect Calendar Clicked",
  },
  CalendarCardClicked: {
    name: "Calendar Card Clicked",
  },
  Customize: {
    name: "Customize",
    channel: {
      dashboardBuilder: "dashboard-builder",
    }
  },
  PersonAdded: {
    name: "Person Added",
    channel: {
      dashboardBuilder: "dashboard-builder",
    }
  },
  PersonRemoved: {
    name: "Person Removed",
    channel: {
      dashboardBuilder: "dashboard-builder",
    }
  },
  AssessmentReminderClicked: {
    name: "Assessment Reminder Clicked",
    source: {
      teamDashboard: "team-dashboard",
      teamSettings: "team-settings",
    }
  },
  MeetingPrepChangeDay: {
    name: "Day Changed",
    props: {
      channel: {
        meetingPrep: "Meeting Prep",
      }
    }
  },
  MeetingPrepSelectEvent: {
    name: "Meeting Selected",
  },
  MeetingPrepCategorySelected: {
    name: "Category changed",
  },
  CoachingFocusSelected: {
    name: "Coaching Focus Selected",
    source: {
      dashBoardProfileCard: "dashboard-profile-card",
      onboarding: "onboarding",
      task: "task",
    }
  },
  CoachingFocusClicked: {
    name: "Coaching Focus Clicked",
    source: {
      dashBoardProfileCard: "dashboard-profile-card",
      onboarding: "onboarding",
      task: "task",
    }
  },
  CoachingFocusUpdated: {
    name: "Coaching Focus Updated",
    source: {
      dashBoardProfileCard: "dashboard-profile-card",
      task: "task",
    }
  },
  PersonaSelected: {
    name: "Persona Selected",
    source: {
      onboarding: "onboarding",
      settings: "settings",
    }
  },
  PersonaUpdated: {
    name: "Persona Updated",
    source: {
      settings: "settings",
    }
  },
  ClearCoachingFocusClicked: {
    name: "Clear Coaching Focus Clicked",
  },
  UpdateCoachingFocusPageViewed: {
    name: "Update Coaching Focus Page Viewed",
    source: {
      email: "email",
    }
  },
  ViewDashboardClicked: {
    name: "View Dashboard Clicked",
  },
  RelationshipAvatarClicked: {
    name: "Relationship Avatar Clicked",
    type: {
      manager: "manager",
      teammate: "teammate",
      report: "report",
    }
  },
  ConnectIntegrationClicked: {
    name: "Connect Integration Clicked",
    source: {
      dailyCoachingCompleted: "daily-coaching-completed",
      integrationSettings: "integration-settings",
      onboarding: "onboarding",
      taskList: "task-list",
    }
  },
  ConnectIntegrationSkipped: {
    name: "Connect Integrations Skipped",
  }
};

export const PageName = {
  p: "profile",
};

export const TaskType = {
  LINK: "LINK",
  CLICKLINK: "CLICKLINK",
  MODAL: "MODAL",
  TYPEFORM: "TYPEFORM",
};

export const TaskMoreInfoType = {
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
};

export const taskNames = {
  UPLOAD_AVATAR: "upload-avatar",
  CONNECT_CALENDAR: "calendar",
  TAKE_STRENGTHSFINDER: "take-strengthsfinder",
  TAKE_SURVEY: "take-survey",
  CUSTOM_TASK: "custom-task",
  CONNECT_MS_TEAMS: "connect-msteams",
};

export const ADAM_SUPPORT_MAIL = "mailto:adam@cloverleaf.me";

export const InvitationType = {
  ORGANIZATION: "ORGANIZATION",
  TEAM: "TEAM",
};

export const ResponseStatus = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  NOT_FOUND: "NOT_FOUND",
  UNAUTHORIZED: "UNAUTHORIZED",
  UNAUTHENTICATED: "UNAUTHENTICATED",
};

export const TEAM_CREATION_PAYWALL_MODAL = "teamCreatePaywallModal";
export const TEAM_MEMBER_PAYWALL_MODAL = "teamMemberPaywallModal";

export const SEGMENT_QUESTION_NUMBER = 99999; // number to use as the question number for segmenting-question, which will never match a real assessment question.

export const SLACK_INSTALL_HELP_URL = "https://help.cloverleaf.me/en/articles/3608795-setting-up-the-slack-integration";

export const SLACK_USE_HELP_URL = "https://help.cloverleaf.me/en/articles/3608795-setting-up-the-slack-integration";

export const MS_TEAMS_HELP_URL = "https://help.cloverleaf.me/en/articles/4092456-microsoft-teams-app-setup";

export const INSTINCTIVE_DRIVES_INTERIM_REPORT_URL = "https://cloverleaf-app-publicfiles.s3.amazonaws.com/reports/instinctivedrives/searching.pdf";

export const EmailCoachingFrequency = {
  DAILY: "DAILY",
  NONE: "NONE",
};

export const DailyCoachingPageType = {
  SELF_INSIGHT: "SELF_INSIGHT",
  TEAM_INSIGHT: "TEAM_INSIGHT",
  TRANSITION: "TRANSITION",
  MORE_TEAMMATE_TIPS: "MORE_TEAMMATE_TIPS",
  INVITE_OTHERS: "INVITE_OTHERS",
};

// graphql/User/index.js line 501
export const CloverleafTier = {
  INDIVIDUAL: "INDIVIDUAL",
  TEAM: "TEAM",
  PLG: "PLG",
  BUSINESS: "BUSINESS",
  COACH_PRO: "COACH_PRO",
  COACH_BASIC: "COACH_BASIC",
  COACH_PREMIUM: "COACH_PREMIUM",
  COACH_LEGACY: "COACH_LEGACY",
  COACH_GROWTH: "COACH_GROWTH",
  COACH_PERFORMANCE: "COACH_PERFORMANCE",
  COACH_EXECUTIVE: "COACH_EXECUTIVE",
  NON_PLAN: "NON_PLAN",
};

// Derived from API constants.js line 719
export const CLOVERLEAF_PLAN_DERIVED_STATUS = {
  DEMO: "DEMO",
  TRIAL: "TRIAL",
  ACTIVE: "ACTIVE",
  TRIAL_EXPIRED: "TRIAL_EXPIRED",
  INACTIVE: "INACTIVE",
  ENGAGEMENT: "ENGAGEMENT",
  PAID_FOR: "PAID_FOR",
  ENGAGEMENT_EXPIRED: "ENGAGEMENT_EXPIRED",
};

export const CLOVERLEAF_PLAN_DERIVED_STATUS_LABELS = {
  DEMO: "Demo",
  TRIAL: "Trial",
  ACTIVE: "Active",
  TRIAL_EXPIRED: "Trial - Expired",
  INACTIVE: "Inactive",
  PAID_FOR: "Paid For",
  ENGAGEMENT: "Engagement",
  ENGAGEMENT_EXPIRED: "Engagement - Expired",
};

const { component: trackingComponent } = EVENT.ContentViewed.props;
export const baseballCardComponentTypes = {
  strengths: trackingComponent.baseballCardStrengthsFinder,
  via: trackingComponent.baseballCardVia,
  personality: trackingComponent.baseballCardSixteenTypes,
  culture: trackingComponent.baseballCardCulturePulse,
  motivatingvalues: trackingComponent.baseballCardMotivatingValues,
  disc: trackingComponent.baseballCardDisc,
  drivingforces: trackingComponent.baseballCardDrivingForces,
  enneagram: trackingComponent.baseballCardEnneagram,
  instinctiveDrives: trackingComponent.baseballCardInstinctiveDrives,
  energyRhythm: trackingComponent.baseballCardEnergyRhythm,
  strengthscope: trackingComponent.baseballCardStrengthscope,
};

export const PAGES = {
  GET_STARTED: "get-started",
  REGISTER_ACCOUNT: "register-account",
  CREATE_ORGANIZATION: "create-organization",
  SELECT_ASSESSMENT: "select-assessment", // pass ref
  CREATE_TEAM: "create-team",
  INVITE_MEMBERS: "invite-members",
  CALENDAR_INTEGRATION: "calendar-integration",
  ASSESSMENT_RESULTS: "assessment-results",
  SURVEY: "survey",
  VERIFY_EMAIL: "verify-email",
};

export const ContextualPromptTriggerType = {
  INITIATE: "INITIATE",
  REACTION: "REACTION",
};

export const USER_RELATIONSHIPS = {
  MANAGER: "MANAGER",
  PEER: "PEER",
};

export const STATUS = {
  INACTIVE: 0, // for a user, this means they've been pre-loaded.
  ACTIVE: 1,
  DELETED: 2,
  APPROVED: 3,
  REJECTED: 4,
  PENDING: 5,
};
