export const REPEATED_EMAIL_ERROR = "Repeated email, emails must be unique.";

export const NO_COLUMNS_MAPPED_TO = "There must be at least one column mapped to";

export const TO_MANY_COLUMNS_MAPPED_TO = "Only one column can be mapped to";

export const INVALID_EMAIL_FORMAT = "Invalid email format";

export const INVALID_MANAGER_EMAIL_FORMAT = "Invalid manager email format";

export const USER_WITH_NO_EMAIL = "User with no email";

export const ORG_ADMIN_MUST_BE = "Org Admin must be one of \"yes, no, y, n\"";

export const REPEATED_TEAM_ERROR = "Repeated team for the same user.";

export const REPEATED_GROUP_ERROR = "Repeated group for the same user.";

export const EMPTY_FIRST_NAME = "Empty first name";

export const EMPTY_LAST_NAME = "Empty last name";
