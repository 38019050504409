import { relayStylePagination } from "@apollo/client/utilities";

const typePolicies = {
  Query: {
    fields: {
      currentUserInfo: {
        merge: true,
      },
      check: {
        merge: true,
      },
      getInsightSearchHistory: {
        merge(_, incoming) {
          return incoming;
        },
      },
      relayUserSearch: relayStylePagination(["organizationId", "userId", "sort", "search", ["text", "topParentId", "userSearchFilters"]]),
      userInsightDirectorySearch: relayStylePagination(["id", "sort", "search"]),
      /**
       * @todo look into doing this properly
       */
      // organization: {
      //   read(_, { args, toReference }) {
      //     return toReference({
      //       __typename: 'Organization',
      //       id: args?.id,
      //     });
      //   }
      // },
    },
  },
  Organization: {
    fields: {
      invitations: {
        keyArgs: false,
        merge(_, incoming) {
          return incoming;
        },
      },
      statistics: {
        merge(existing = [], incoming) {
          return {
            ...existing,
            ...incoming,
          };
        },
      },
    },
  },
  ConfigurationSetting: {
    keyFields: ["setting"],
    fields: {
      options: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  ConfigurationOption: {
    keyFields: ["setting"],
  },
  OrganizationStatistics: {
    fields: {
      dataRows: {
        keyArgs: ["relayType"],
        merge: (existing = {}, incoming = {}) => {
          const mergedEdges = [
            ...(existing?.edges ?? []),
            ...(incoming?.edges ?? []),
          ];

          return {
            ...incoming,
            edges: mergedEdges,
          };
        },
      }
    },
  },
  TeamInvitation: {
    keyFields: ["email"],
  },
  User: {
    fields: {
      admin: {
        merge: true,
      },
      stateData: {
        merge: true,
      },
    },
  },
  Team: {
    fields: {
      /** @todo - cannot automatically merge array objects without an id, needs to be fixed */
      // admins: {},
      assessments: {
        merge: true,
      },
      invitations: {
        keyFields: ["email"],
      },
      /** @todo - cannot automatically merge array objects without an id, needs to be fixed */
      // invitations: {},
      users: {
        keyArgs: false,
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  PLGPlan: {
    merge: true,
  },
  Scores: {
    fields: {
      strengthscope: {
        merge: true,
      },
    },
    merge: true,
  },
  DefaultSuggestionResult: {
    keyFields: ["version"],
  },
  StyledStringSuggestion: {
    merge(_, incoming) {
      return incoming;
    },
  },
  CalendarTips: {
    keyFields: false,
  }
};

export default typePolicies;
