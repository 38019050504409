export const colors = {
  blue50: "#DBF6FC",
  blue400: "#E9F2F2",
  blue500: "#CCEBF1",
  blue600: "#008299",
  blue700: "#00697C",
  blue800: "#003E49",

  grey50: "#FBFBFB",
  grey100: "#F6F6F6",
  grey200: "#F1F1F1",
  grey300: "#E5E5E5",
  grey400: "#C3C3C3",
  grey500: "#A5A5A5",
  grey600: "#7B7B7B",
  grey700: "#676767",
  grey800: "#484848",
  grey900: "#262626",

  green50: "#F9FFFB",
  green100: "#ECFFF2",
  green200: "#BDF0CF",
  green300: "#7ADBA1",
  green400: "#0FC16B",
  green500: "#009E52",
  green600: "#00853D",
  green700: "#006528",
  green800: "#00481D",
  green900: "#003013",

  purple50: "#F6E4FF",
  purple500: "#8B14C4",
  purple600: "#7700AF",
  purple700: "#5F008C",

  red50: "#FBE8EA",
  red100: "#FFC7C9",
  red200: "#F89792",
  red300: "#EB7069",
  red500: "#D02E18",
  red600: "#C22319",
  red700: "#A71A11",
  red800: "#92160E",
  red900: "#700D07",

  yellow50: "#FFFEEB",
  yellow500: "#ffe25f",
  yellow900: "#8F6505",

  white50: "#F8F8F8",
  white80: "#ffffffcc",

  brand: {
    dark: "green500",
    light: "#00B85F",
  },
  background: {
    dark: "#111111",
    light: "#FFFFFF",
  },
  "background-back": {
    dark: "#111111",
    light: "#EEEEEE",
  },
  "background-front": {
    dark: "#222222",
    light: "#FFFFFF",
  },
  "background-contrast": {
    dark: "#60b978",
    light: "#519450",
  },
  "background-light-blue": {
    dark: "#111111",
    light: "#E9F2F2",
  },
  "background-mono": {
    dark: "#444444",
    light: "#CCCCCC",
  },
  "background-gradient": "linear-gradient(to bottom right, #016629, #017F96)",
  text: {
    dark: "white",
    light: "grey800",
  },
  "text-brand": {
    dark: "white",
    light: "green600",
  },
  "text-link": {
    dark: "#CCCCCC",
    light: "#444444",
  },
  "text-sidebar": {
    dark: "white",
    light: "grey700",
  },
  "text-strong": {
    dark: "#FFFFFF",
    light: "#000000",
  },
  "text-weak": {
    dark: "#CCCCCC",
    light: "#444444",
  },
  "text-xweak": {
    dark: "#999999",
    light: "#666666",
  },
  border: {
    dark: "#444444",
    light: "#CCCCCC",
  },
  control: "brand",
  "active-background": "background-contrast",
  "active-text": "text-strong",
  "selected-background": "grey100",
  "selected-text": "text-strong",
  "status-critical": {
    dark: "red50",
    light: "red700",
  },
  "status-warning": {
    dark: "yellow50",
    light: "yellow900",
  },
  "status-ok": {
    dark: "green100",
    light: "green600",
  },
  "status-info": {
    dark: "purple50",
    light: "purple600",
  },
  "status-error": "#C22319",
  "status-unknown": "#CCCCCC",
  "status-disabled": "#CCCCCC",
  "graph-0": "brand",
  "graph-1": "status-warning",
  focus: "status-ok",
};
